import binarySearch from './binarySearch'

export function parseOWScrims(scrims) {
  let scrim_stats = {}
  for (let scrim of scrims) {
    try {
      let curr_scrim_stats = parseOWScrim(scrim)
      if (!(scrim.map.name in scrim_stats)) scrim_stats[scrim.map.name] = []
      scrim_stats[scrim.map.name].push(curr_scrim_stats)
    } catch (e) {
      console.log('Error in parsing scrim')
    }
    //END OF SCRIMS LOOP
  }
  return scrim_stats
}

function parseOWScrim(scrim) {
  let curr_scrim_stats = {
    map: scrim.map.name,
    scrim_date: scrim.scrim_date,
    team_comp: [],
    players: {},
    match: scrim.matchInfo.pseudo_match_id,
    win: scrim.score.won > scrim.score.lost ? 1 : 0,
    round_wins: 0,
    round_losses: 0,
    atk: {
      wins: 0,
      total: 0,
      pistol_wins: 0,
      pistol_total: 0,
      first_kill: [],
      first_death: [],
      xvy: [],
      first_state: [],
      postplant: [],
      ults: [],
    },
    def: {
      wins: 0,
      total: 0,
      pistol_wins: 0,
      pistol_total: 0,
      first_kill: [],
      first_death: [],
      xvy: [],
      first_state: [],
      postplant: [],
      ults: [],
    },
  }

  let ult_count = {}

  for (let player of scrim.roster) {
    if (player.teammate) {
      curr_scrim_stats.team_comp.push(player.agent)

      curr_scrim_stats.players[player.player_id] = {
        id: player.player_id,
        agent: player.agent,
        name: player.killfeedName,
      }

      ult_count[player.player_id] = {
        id: player.player_id,
        agent: player.agent,
        count: 0,
      }
    }
  }

  curr_scrim_stats.team_comp.sort()

  scrim.rounds.forEach(round => {
    if (round === null) return

    let round_win = round.won ? 1 : 0
    let role = round.team_role

    curr_scrim_stats.round_wins += round_win
    curr_scrim_stats.round_losses += round_win === 1 ? 0 : 1

    //PISTOL
    if (round.round_num === 0 || round.round_num === 12) {
      curr_scrim_stats[role].pistol_total += 1
      curr_scrim_stats[role].pistol_wins += round_win
    }

    //NORMAL ROUNDS
    curr_scrim_stats[role].total += 1
    curr_scrim_stats[role].wins += round_win

    const round_end_millis = round.phases.end.timestamp - round.phases.combat.timestamp
    let outcome = {
      ...(round.spike || {
        planted: null,
        defused: null,
        detonated: null,
      }),
      win: round_win,
      round_num: round.round_num + 1,
      round_end_millis: round_end_millis,
    }

    if ('spike' in round && round.spike.planted !== null) {
      if (round.spike.planted < round.phases.end.timestamp) {
        const idx = binarySearch(round.killfeed, k => k.timestamp >= round.spike.planted)
        round.killfeed.splice(idx, 0, {
          type: 'plant',
          round_time_millis: round.spike.planted - round.phases.combat.timestamp,
        })
        outcome.planted = outcome.planted - round.phases.combat.timestamp
        outcome.defused = outcome.defused === null ? null : outcome.defused - round.phases.combat.timestamp
        outcome.detonated = outcome.detonated === null ? null : outcome.detonated - round.phases.combat.timestamp
      } else outcome.planted = null
    }

    let events = [
      { type: 'round_start', round_time_millis: 0 },
      ...round.killfeed.filter(k => k.round_time_millis <= round_end_millis),
      { type: 'round_end', round_time_millis: round_end_millis },
    ]

    let feed_stats = processFeedStats(events, curr_scrim_stats.players, outcome)

    if (feed_stats.first_kill !== null) curr_scrim_stats[role].first_kill.push(feed_stats.first_kill)
    if (feed_stats.first_death !== null) curr_scrim_stats[role].first_death.push(feed_stats.first_death)

    if (feed_stats.xvy.length > 0) curr_scrim_stats[role].xvy.push(feed_stats.xvy)
    if (feed_stats.first_state !== null) curr_scrim_stats[role].first_state.push(feed_stats.first_state)

    if (feed_stats.postplant !== null) curr_scrim_stats[role].postplant.push(feed_stats.postplant)

    //At Round Start
    round.phases.shopping?.scoreboard_end?.forEach(p => {
      if (!(p.player_id in ult_count)) return
      if (p.ult_max !== 0 && p.ult_points === p.ult_max) {
        ult_count[p.player_id].ready = true
      } else {
        ult_count[p.player_id].ready = false
      }
      ult_count[p.player_id].count = p.ult_points
    })

    let ult_stats = processUltStats(round.ultimateCounts, ult_count, outcome)

    if (ult_stats.pre.length > 0 || ult_stats.post.length > 0) curr_scrim_stats[role].ults.push(ult_stats)

    //END OF ROUNDS LOOP
  })

  return curr_scrim_stats
}

function processFeedStats(feed, players, outcome) {
  let stats = {
    first_kill: null,
    first_death: null,
    first_state: { type: 0, state: {} }, //1 Man Adv, -1 Man DisAdv, 0 Equal (first tries to find a stable state)
    xvy: [],
    postplant: null,
  }

  let first = null
  let prev_idx = 0
  let player_count = { team: 5, enemy: 5 }

  feed.forEach((k, idx) => {
    let event = 'type' in k ? k.type : 'kill'
    if (event === 'kill' && 'damage_agent' in k.finishing_damage && k.finishing_damage.damage_agent === 'Sage')
      event = 'revive'
    if (event === 'kill' && k.finishing_damage === 'Bomb') return

    //FKD Related & Traded FKD
    if (first === null && event === 'kill') {
      first = k

      let possible_trades = feed.filter(
        kill =>
          !('type' in kill) &&
          kill.is_attacker_teammate != kill.is_victim_teammate &&
          kill != first &&
          kill.finishing_damage != 'Bomb' &&
          kill.round_time_millis - first.round_time_millis < 3000 &&
          first.attacker_player_id === kill.victim_player_id
      )

      if (k.is_victim_teammate) {
        stats.first_death = {
          player: players[k.victim_player_id],
          traded: possible_trades.length !== 0 ? true : false,
          win: outcome.win,
        }
      }

      if (k.is_attacker_teammate && !k.is_victim_teammate ? true : false) {
        stats.first_kill = {
          player: players[k.attacker_player_id],
          traded: possible_trades.length !== 0 ? true : false,
          win: outcome.win,
        }
      }
    }

    if (event === 'kill' || event === 'revive' || event === 'round_end') {
      if (player_count.team > 0 && player_count.enemy > 0 && !(player_count.team === 5 && player_count.enemy === 5))
        stats.xvy.push({
          round_num: outcome.round_num,
          team: player_count.team,
          enemy: player_count.enemy,
          stable: k.round_time_millis - feed[prev_idx].round_time_millis >= 3000,
          start_round_millis: feed[prev_idx].round_time_millis,
          end_round_millis: k.round_time_millis,
          win: outcome.win,
          pre_post: statePrePost(feed[prev_idx].round_time_millis, k.round_time_millis, outcome.planted),
        })

      if (event === 'round_end') return
      const victim = k.is_victim_teammate ? 'team' : 'enemy'
      player_count[victim] += event === 'kill' ? -1 : 1
      prev_idx = idx
    }

    if (event === 'plant' && player_count.team !== 0 && player_count.enemy !== 0) {
      stats.postplant = {
        round_num: outcome.round_num,
        team: player_count.team,
        enemy: player_count.enemy,
        plant_round_millis: k.round_time_millis,
        win: outcome.win,
        defused: outcome.defused ? true : false,
      }
    }
    //FEED END
  })

  if (stats.xvy.length > 0) {
    let first_state = stats.xvy.find(s => s.stable)
    if (!first_state) first_state = stats.xvy[0]

    if (first_state.team > first_state.enemy) stats.first_state.type = 1
    else if (first_state.team < first_state.enemy) stats.first_state.type = -1

    stats.first_state.state = first_state
  } else stats.first_state = null

  return stats
}

function statePrePost(start, end, plant) {
  if (plant === null) return 'pre'
  if (end < plant) return 'pre'
  if (start >= plant) return 'post'
  if (start < plant && end >= plant) return 'both'
}

function processUltStats(ults, players, outcome) {
  let stats = {
    round_num: outcome.round_num,
    planted: outcome.planted === null ? false : true,
    defused: outcome.defused === null ? false : true,
    win: outcome.win,
    pre: [],
    post: [],
  }

  ults.forEach(u => {
    if (!(u.player_id in players)) return
    if (u.round_time_millis > outcome.round_end_millis) return

    if (u.count !== players[u.player_id].count) {
      if (u.count === 0) {
        let objective_time = statePrePost(u.round_time_millis, u.round_time_millis, outcome.planted)

        stats[objective_time].push({
          pre_post: objective_time,
          agent: players[u.player_id].agent,
          player: players[u.player_id].id,
        })
      }
      players[u.player_id].count = u.count
    }
  })
  return stats
}
