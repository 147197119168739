<template>
  <div
    class="ic--container"
    :class="`ic--container__${variant} ic--container__${highlight ? 'highlight' : 'nohighlight'}`"
    :title="computedTitle"
    v-b-tooltip.hover.noninteractive
  >
    <i
      v-if="image"
      class="ic--icon"
      :class="`ic--icon__${size} ic--icon__${highlight ? 'highlight' : 'nohighlight'} ${
        border ? 'ic--icon__border' : ''
      } ${transparent ? 'ic--icon__transparent' : ''}`"
      :style="`background-image: url(${image})`"
    />
    <span v-if="image" class="ic--space" />
    <span class="ic--label" :class="`ic--label__${highlight ? 'highlight' : 'nohighlight'}`" :title="label">
      <slot>
        {{ label }}
      </slot>
    </span>
  </div>
</template>

<script>
import px from 'vue-types'

import { pxNullable } from '../Map/types.js'

export default {
  name: 'ImgCell',
  props: {
    highlight: px.bool.def(false),
    image: pxNullable(px.string).def(null),
    label: pxNullable(px.string).def(null),
    size: px.oneOf(['xs', 'sm', 'md', 'lg']).def('md'),
    variant: px.oneOf(['col', 'row', 'icon', 'row-reverse']).def('col'),
    border: px.bool.def(true),
    transparent: px.bool.def(false),
  },
  computed: {
    computedTitle() {
      return this.variant === 'icon' ? this.label : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.ic {
  &--container {
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    min-width: 0;

    &__col {
      flex-direction: column;
    }

    &__row {
      flex-direction: row;
    }

    &__row-reverse {
      flex-direction: row-reverse;
    }

    &__icon {
      .ic--space,
      .ic--label {
        display: none;
      }
    }
  }

  &--icon {
    display: inline-block;
    background-position: center;
    background-size: cover;
    object-fit: cover;
    flex: 0 0 auto;
    background-color: #444;
    margin: 2px;

    &__transparent {
      background-color: transparent;
    }

    &__border {
      border: 1px solid #ccc;
    }

    &__xs {
      width: 1.25em;
      height: 1.25em;
    }

    &__sm {
      width: 2em;
      height: 2em;
    }

    &__md {
      width: 3em;
      height: 3em;
    }

    &__lg {
      width: 4em;
      height: 4em;
    }

    &__highlight {
      background-color: $body-bg;
      outline: 2px solid $highlight;
    }
  }

  &--space {
    display: block;
    width: 0.5em;
    height: 0.25em;
    flex: 0 0 auto;
  }

  &--label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
