<template>
  <div>
    <div class="tooltipmain">
      ?
      <span class="tooltiptext">
        <p>Pre-Plant: Any ults used before the Spike Plant or a Round Win by Elimination (w/o plant).</p>
        <p>
          Usage Rate: Rounds with an ult used over total rounds (w/o filtering of rounds with Ults Ready at Round Start
          or gained In-Round).
        </p>
        <p>Plants: If any of the rounds with an ult used resulted to a plant.</p>
        <p>Win Rate: Round Wins of Rounds with Ults Used.</p>
      </span>
    </div>
    <AppTable :items="getPreData" :fields="preFields">
      <template #thead-top>
        <b-tr>
          <b-th colspan="7" style="text-align: left"> ULT ROUND EFFECT: PRE-PLANT </b-th>
        </b-tr>
      </template>

      <template #cell(comp)="row">
        <div :title="row.item.title" v-b-tooltip.hover.noninteractive style="display: inline-flex; align-items: center">
          <BIconCaretRightFill
            v-if="row.item.comp !== 'ALL'"
            :class="row.detailsShowing ? 'expanded' : 'collapsed'"
            class="toggle-details-icon"
            @click="row.toggleDetails"
          />
          <div v-if="row.item.comp === 'ALL'">ALL</div>
          <div v-else>
            <AgentComposition :agent_ids="row.item.comp" size="sm" />
          </div>
        </div>
      </template>

      <template #cell(atk_usage)="field">
        <div>
          <strong>{{ field.item.atk.total | percentage(field.item.atk.rounds) }}</strong>
          <span class="text-muted ml-1">({{ field.item.atk.total }} / {{ field.item.atk.rounds }})</span>
        </div>
      </template>

      <template #cell(atk_plants)="field">
        <div>
          <strong>{{ field.item.atk.plants }}</strong>
        </div>
      </template>

      <template #cell(atk_defuses)="field">
        <div>
          <strong>{{ field.item.atk.defuses }}</strong>
        </div>
      </template>

      <template #cell(atk_wr)="field">
        <div>
          <strong>{{ field.item.atk.wins | percentage(field.item.atk.total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.atk.wins }} / {{ field.item.atk.total }})</span>
        </div>
      </template>

      <template #cell(def_usage)="field">
        <div>
          <strong>{{ field.item.def.total | percentage(field.item.def.rounds) }}</strong>
          <span class="text-muted ml-1">({{ field.item.def.total }} / {{ field.item.def.rounds }})</span>
        </div>
      </template>

      <template #cell(def_plants)="field">
        <div>
          <strong>{{ field.item.def.plants }}</strong>
        </div>
      </template>

      <template #cell(def_defuses)="field">
        <div>
          <strong>{{ field.item.def.defuses }}</strong>
        </div>
      </template>

      <template #cell(def_wr)="field">
        <div>
          <strong>{{ field.item.def.wins | percentage(field.item.def.total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.def.wins }} / {{ field.item.def.total }})</span>
        </div>
      </template>

      <template #row-details="row">
        <div style="display: flex; flex-direction: row">
          <AppTable :items="row.item.atk.items" :fields="preItemFields">
            <template #thead-top>
              <b-tr>
                <b-th colspan="3" class="atk">ATK</b-th>
              </b-tr>
            </template>

            <template #cell(ults)="field">
              <div>
                <AgentComposition :agent_ids="field.item.ults" size="sm" />
              </div>
            </template>

            <template #cell(win)="field">
              <div v-if="field.item.win" class="won">✔</div>
              <div v-else class="lost">✘</div>
            </template>

            <template #cell(planted)="field">
              <div v-if="field.item.planted" class="won">✔</div>
              <div v-else class="lost">✘</div>
            </template>
          </AppTable>

          <AppTable :items="row.item.def.items" :fields="preItemFields">
            <template #thead-top>
              <b-tr>
                <b-th colspan="3" class="def">DEF</b-th>
              </b-tr>
            </template>

            <template #cell(ults)="field">
              <div>
                <AgentComposition :agent_ids="field.item.ults" size="sm" />
              </div>
            </template>

            <template #cell(win)="field">
              <div v-if="field.item.win" class="won">✔</div>
              <div v-else class="lost">✘</div>
            </template>

            <template #cell(planted)="field">
              <div v-if="field.item.planted" class="won">✔</div>
              <div v-else class="lost">✘</div>
            </template>
          </AppTable>
        </div>
      </template>
    </AppTable>

    <div class="tooltipmain">
      ?
      <span class="tooltiptext">
        <p>Post-Plant: Any ults used after the Spike Plant.</p>
        <p>
          Usage Rate: Post-Plants with an ult used over total plants (w/o filtering of Post-Plants with Ults Ready at
          Round Start or gained In-Round).
        </p>
        <p>Win Rate: Round Wins of Rounds with Ults Used.</p>
      </span>
    </div>

    <AppTable :items="getPostData" :fields="postFields">
      <template #thead-top>
        <b-tr>
          <b-th colspan="5" style="text-align: left"> ULT ROUND EFFECT: POST-PLANT </b-th>
        </b-tr>
      </template>

      <template #cell(comp)="row">
        <div :title="row.item.title" v-b-tooltip.hover.noninteractive style="display: inline-flex; align-items: center">
          <BIconCaretRightFill
            v-if="row.item.comp !== 'ALL'"
            :class="row.detailsShowing ? 'expanded' : 'collapsed'"
            class="toggle-details-icon"
            @click="row.toggleDetails"
          />
          <div v-if="row.item.comp === 'ALL'">ALL</div>
          <div v-else>
            <AgentComposition :agent_ids="row.item.comp" size="sm" />
          </div>
        </div>
      </template>

      <template #cell(atk_usage)="field">
        <div>
          <strong>{{ field.item.atk.total | percentage(field.item.atk.plants) }}</strong>
          <span class="text-muted ml-1">({{ field.item.atk.total }} / {{ field.item.atk.plants }})</span>
        </div>
      </template>

      <template #cell(atk_wr)="field">
        <div>
          <strong>{{ field.item.atk.wins | percentage(field.item.atk.total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.atk.wins }} / {{ field.item.atk.total }})</span>
        </div>
      </template>

      <template #cell(def_usage)="field">
        <div>
          <strong>{{ field.item.def.total | percentage(field.item.def.plants) }}</strong>
          <span class="text-muted ml-1">({{ field.item.def.total }} / {{ field.item.def.plants }})</span>
        </div>
      </template>

      <template #cell(def_wr)="field">
        <div>
          <strong>{{ field.item.def.wins | percentage(field.item.def.total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.def.wins }} / {{ field.item.def.total }})</span>
        </div>
      </template>

      <template #row-details="row">
        <div style="display: flex; flex-direction: row">
          <AppTable :items="row.item.atk.items" :fields="postItemFields">
            <template #thead-top>
              <b-tr>
                <b-th colspan="2" class="atk">ATK</b-th>
              </b-tr>
            </template>

            <template #cell(ults)="field">
              <div>
                <AgentComposition :agent_ids="field.item.ults" size="sm" />
              </div>
            </template>

            <template #cell(win)="field">
              <div v-if="field.item.win" class="won">✔</div>
              <div v-else class="lost">✘</div>
            </template>
          </AppTable>

          <AppTable :items="row.item.def.items" :fields="postItemFields">
            <template #thead-top>
              <b-tr>
                <b-th colspan="2" class="def">DEF</b-th>
              </b-tr>
            </template>

            <template #cell(ults)="field">
              <div>
                <AgentComposition :agent_ids="field.item.ults" size="sm" />
              </div>
            </template>

            <template #cell(win)="field">
              <div v-if="field.item.win" class="won">✔</div>
              <div v-else class="lost">✘</div>
            </template>
          </AppTable>
        </div>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BIconCaretRightFill } from 'bootstrap-vue'

import AppTable from '@/components/generic/Table.vue'
import AgentComposition from '@/components/UI/AgentComposition.vue'

import percentage from '../../filters/percentage.js'

export default {
  name: 'UltTable',
  components: {
    AgentComposition,
    AppTable,
    BIconCaretRightFill,
  },
  props: {
    stats: [],
  },
  data() {
    return {
      side: 'atk',
      plantStatus: 'pre',
      preFields: [
        { key: 'comp', label: 'Team Composition', thClass: 'text-left', tdClass: 'text-left' },
        { key: 'atk_usage', label: 'Usage Rate', thClass: 'atk' },
        { key: 'atk_plants', label: 'Plants', thClass: 'atk' },
        { key: 'atk_defuses', label: 'Defuses', thClass: 'atk' },
        { key: 'atk_wr', label: 'Winrate', thClass: 'atk' },
        { key: 'def_usage', label: 'Usage Rate', thClass: 'def' },
        { key: 'def_plants', label: 'Plants', thClass: 'def' },
        { key: 'def_defuses', label: 'Defuses', thClass: 'def' },
        { key: 'def_wr', label: 'Winrate', thClass: 'def' },
      ],
      postFields: [
        { key: 'comp', label: 'Team Composition', thClass: 'text-left', tdClass: 'text-left' },
        { key: 'atk_usage', label: 'Usage Rate', thClass: 'atk' },
        { key: 'atk_wr', label: 'Winrate', thClass: 'atk' },
        { key: 'def_usage', label: 'Usage Rate', thClass: 'def' },
        { key: 'def_wr', label: 'Winrate', thClass: 'def' },
      ],
      preItemFields: [
        { key: 'ults', label: 'Ultimates Used', thClass: 'text-left' },
        { key: 'win', label: 'Won' },
        { key: 'planted', label: 'Plants' },
      ],
      postItemFields: [
        { key: 'ults', label: 'Ultimates Used', thClass: 'text-left' },
        { key: 'win', label: 'Won' },
      ],
    }
  },
  computed: {
    getPreData() {
      let stats = []
      this.stats.forEach(s => {
        stats.push({
          comp: s.comp,
          atk: {
            rounds: s.atk.total,
            wins: s.atk.ult_pre_wins,
            total: s.atk.ult_pre_total,
            plants: s.atk.ult_pre_plants,
            defuses: s.atk.ult_pre_defuses,
            items: s.atk.ult_pre_items,
          },
          def: {
            rounds: s.def.total,
            wins: s.def.ult_pre_wins,
            total: s.def.ult_pre_total,
            plants: s.def.ult_pre_plants,
            defuses: s.def.ult_pre_defuses,
            items: s.def.ult_pre_items,
          },
        })
      })
      return stats
    },
    getPostData() {
      let stats = []
      this.stats.forEach(s => {
        stats.push({
          comp: s.comp,
          atk: {
            plants: s.atk.postplant_total,
            total: s.atk.ult_post_total,
            wins: s.atk.ult_post_wins,
            items: s.atk.ult_post_items,
          },
          def: {
            plants: s.def.postplant_total,
            total: s.def.ult_post_total,
            wins: s.def.ult_post_wins,
            items: s.def.ult_post_items,
          },
        })
      })
      return stats
    },
  },
  filters: {
    percentage,
  },
  methods: {
    clickSide(value) {
      this.side = value
    },
    clickPlant(value) {
      this.plantStatus = value
    },
  },
}
</script>

<style scoped lang="scss">
.buttonGroup {
  width: 640px;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  place-items: center;
  margin-bottom: 8px;
}

button {
  width: max-content;
  min-width: 60px;
  background-color: #282828;
  color: white;
  border: 1px solid black;
  border-radius: 0.5rem;
}

.def {
  background-color: #099b83;
}

.atk {
  background-color: #ac493a;
}

.active {
  font-weight: 700;
  border: 1px solid white;
}

.tooltipmain {
  display: inline-block;
  margin-left: 8px;
  text-align: center;
  justify-self: end;
  background-color: white;
  color: black;
  font-weight: 700;
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  cursor: pointer;
  position: relative;
}

.tooltipmain .tooltiptext {
  visibility: hidden;
  width: 500px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: #e4e4e7;
  color: black;
  text-align: left;
  border-radius: 1rem;
  padding: 8px;
  position: absolute;
  z-index: 1;
  left: 650%;
  top: -36px;
  margin-left: -120px;
  font-size: 0.75rem;
  line-height: 1rem;
}

.tooltipmain:hover .tooltiptext {
  visibility: visible;
}

.status {
  font-weight: 700;
  border: 1px solid white;
  color: black;
  background-color: white;
}

.won {
  color: green;
}

.lost {
  color: red;
}

.collapsed {
  transform: rotate(0);
}

.expanded {
  transform: rotate(90deg);
}

::v-deep {
  .cursor-pointer:not(.b-table-details) {
    cursor: pointer;
  }

  &.table-hover .table-dark:hover {
    background-color: #131313;
  }

  td,
  th {
    text-align: center;
  }
  .def {
    background-color: #099b83;
  }

  .atk {
    background-color: #ac493a;
  }
}
</style>
