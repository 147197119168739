<template>
  <div>
    <AppTable :items="stats" :fields="fields">
      <template #thead-top>
        <b-tr>
          <b-th colspan="4" style="text-align: left"> OVERALL WINRATE </b-th>
        </b-tr>
      </template>

      <template #cell(comp)="field">
        <div v-if="field.item.comp === 'ALL'">ALL</div>
        <div v-else>
          <AgentComposition :agent_ids="field.item.comp" size="sm" />
        </div>
      </template>

      <template #cell(match)="field">
        <div>
          <strong>{{ field.item.match.wins | percentage(field.item.match.total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.match.wins }} / {{ field.item.match.total }})</span>
        </div>
      </template>

      <template #cell(atk)="field">
        <div>
          <strong>{{ field.item.atk.wins | percentage(field.item.atk.total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.atk.wins }} / {{ field.item.atk.total }})</span>
        </div>
      </template>

      <template #cell(def)="field">
        <div>
          <strong>{{ field.item.def.wins | percentage(field.item.def.total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.def.wins }} / {{ field.item.def.total }})</span>
        </div>
      </template>
    </AppTable>

    <AppTable :items="stats" :fields="fields">
      <template #thead-top>
        <b-tr>
          <b-th colspan="4" style="text-align: left"> PISTOL WINRATE </b-th>
        </b-tr>
      </template>

      <template #cell(comp)="field">
        <div v-if="field.item.comp === 'ALL'">ALL</div>
        <div v-else>
          <AgentComposition :agent_ids="field.item.comp" size="sm" />
        </div>
      </template>

      <template #cell(match)="field">
        <div>
          <strong>{{ field.item.match.wins | percentage(field.item.match.total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.match.wins }} / {{ field.item.match.total }})</span>
        </div>
      </template>

      <template #cell(atk)="field">
        <div>
          <strong>{{ field.item.atk.pistol_wins | percentage(field.item.atk.pistol_total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.atk.pistol_wins }} / {{ field.item.atk.pistol_total }})</span>
        </div>
      </template>

      <template #cell(def)="field">
        <div>
          <strong>{{ field.item.def.pistol_wins | percentage(field.item.def.pistol_total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.def.pistol_wins }} / {{ field.item.def.pistol_total }})</span>
        </div>
      </template>
    </AppTable>
  </div>
</template>

<script>
import AppTable from '@/components/generic/Table.vue'
import AgentComposition from '@/components/UI/AgentComposition.vue'

import percentage from '../../filters/percentage.js'

export default {
  name: 'WinRateTable',
  components: {
    AgentComposition,
    AppTable,
  },
  props: {
    stats: [],
  },
  data() {
    return {
      fields: [
        { key: 'comp', label: 'Team Composition', thClass: 'text-left', tdClass: 'text-left' },
        { key: 'match', label: 'Matches' },
        { key: 'atk', label: 'ATK Rounds', thClass: 'atk' },
        { key: 'def', label: 'DEF Rounds', thClass: 'def' },
      ],
    }
  },
  filters: {
    percentage,
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .cursor-pointer:not(.b-table-details) {
    cursor: pointer;
  }

  &.table-hover .table-dark:hover {
    background-color: #131313;
  }

  td,
  th {
    text-align: center;
  }

  .def {
    background-color: #099b83;
  }

  .atk {
    background-color: #ac493a;
  }
}
</style>
