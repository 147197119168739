<template>
  <div>
    <div class="buttonGroup">
      <button @click="clickFKDFilter('all')" :class="{ active: fkdFilter === 'all' }">All</button>
      <button @click="clickFKDFilter('true')" :class="{ active: fkdFilter === 'true' }">True</button>
      <button @click="clickFKDFilter('traded')" :class="{ active: fkdFilter === 'traded' }">Traded</button>

      <div class="tooltipmain">
        ?
        <span class="tooltiptext">
          True First Kills/Deaths indicate that the opening kill was not traded. A Trade is a kill towards the victim's
          killer within 3 seconds. True FK is the favored stat because your team won the opening duel without losing the
          player who got the FK. Traded FD is the favored stat because of immediately equalizing after losing the
          opening duel.
        </span>
      </div>
    </div>

    <AppTable :items="stats" :fields="fkdFields">
      <template #thead-top>
        <b-tr>
          <b-th colspan="7" style="text-align: left"> FIRST KILL/DEATH </b-th>
        </b-tr>
      </template>

      <template #cell(comp)="field">
        <div v-if="field.item.comp === 'ALL'">ALL</div>
        <div v-else>
          <AgentComposition :agent_ids="field.item.comp" size="sm" />
        </div>
      </template>

      <template #cell(atk)="field">
        <div>
          <strong>{{ field.item.atk.total }}</strong>
        </div>
      </template>

      <template #cell(atk_fk)="field">
        <div>
          <strong>{{
            field.item.atk[fkdKeys[fkdFilter] + 'fk_wins'] | percentage(field.item.atk[fkdKeys[fkdFilter] + 'fk_total'])
          }}</strong>
          <span class="text-muted ml-1"
            >({{ field.item.atk[fkdKeys[fkdFilter] + 'fk_wins'] }} /
            {{ field.item.atk[fkdKeys[fkdFilter] + 'fk_total'] }})</span
          >
        </div>
      </template>

      <template #cell(atk_fd)="field">
        <div>
          <strong>{{
            field.item.atk[fkdKeys[fkdFilter] + 'fd_wins'] | percentage(field.item.atk[fkdKeys[fkdFilter] + 'fd_total'])
          }}</strong>
          <span class="text-muted ml-1"
            >({{ field.item.atk[fkdKeys[fkdFilter] + 'fd_wins'] }} /
            {{ field.item.atk[fkdKeys[fkdFilter] + 'fd_total'] }})</span
          >
        </div>
      </template>

      <template #cell(def)="field">
        <div>
          <strong>{{ field.item.def.total }}</strong>
        </div>
      </template>

      <template #cell(def_fk)="field">
        <div>
          <strong>{{
            field.item.def[fkdKeys[fkdFilter] + 'fk_wins'] | percentage(field.item.def[fkdKeys[fkdFilter] + 'fk_total'])
          }}</strong>
          <span class="text-muted ml-1"
            >({{ field.item.def[fkdKeys[fkdFilter] + 'fk_wins'] }} /
            {{ field.item.def[fkdKeys[fkdFilter] + 'fk_total'] }})</span
          >
        </div>
      </template>

      <template #cell(def_fd)="field">
        <div>
          <strong>{{
            field.item.def[fkdKeys[fkdFilter] + 'fd_wins'] | percentage(field.item.def[fkdKeys[fkdFilter] + 'fd_total'])
          }}</strong>
          <span class="text-muted ml-1"
            >({{ field.item.def[fkdKeys[fkdFilter] + 'fd_wins'] }} /
            {{ field.item.def[fkdKeys[fkdFilter] + 'fd_total'] }})</span
          >
        </div>
      </template>
    </AppTable>

    <div class="buttonGroup">
      <button @click="clickFirstStateFilter('adv')" :class="{ active: firstStateFilter === 'adv' }">
        Man Advantage
      </button>
      <button @click="clickFirstStateFilter('eq')" :class="{ active: firstStateFilter === 'eq' }">Equal</button>
      <button @click="clickFirstStateFilter('dadv')" :class="{ active: firstStateFilter === 'dadv' }">
        Man Disadvantage
      </button>

      <button @click="clickFirstStateStable" class="stableButton">
        Only Stable States:
        <template v-if="firstStateStable">Yes</template>
        <template v-else>No</template>
      </button>
      <div class="tooltipmain">
        ?
        <span class="tooltiptext">
          An FK/D does not always mean a perfect 5v4 or 4v5 situation. You can get a True First Kill while at the same
          time lose a player at a different part of the map (meaning a 4v4 not a 5v4). A Stable State means no kills
          were made after 3 seconds from the latest kill. Sometimes there are no stable first states like in a 5-man
          rush where a whole team dies w/o 3 seconds in between player deaths, so we just record a 4v5/5v4.
        </span>
      </div>
    </div>

    <AppTable :items="firstStateStats" :fields="firstStateFields">
      <template #thead-top>
        <b-tr>
          <b-th colspan="9" style="text-align: left"> FIRST STATE </b-th>
        </b-tr>
      </template>

      <template #cell(comp)="row">
        <div
          :title="row.item.title"
          v-b-tooltip.hover.noninteractive
          style="display: inline-flex; align-items: center"
          @click="row.toggleDetails"
        >
          <BIconCaretRightFill :class="row.detailsShowing ? 'expanded' : 'collapsed'" class="toggle-details-icon" />
          <div v-if="row.item.comp === 'ALL'">ALL</div>
          <div v-else>
            <AgentComposition :agent_ids="row.item.comp" size="sm" />
          </div>
        </div>
      </template>

      <template #cell(atk)="field">
        <div>
          <strong>{{ field.item.atk.total }}</strong>
        </div>
      </template>

      <template #cell(atk_adv)="field">
        <div>
          <strong>{{ field.item.atk.adv_wins | percentage(field.item.atk.adv_total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.atk.adv_wins }} / {{ field.item.atk.adv_total }})</span>
        </div>
      </template>

      <template #cell(atk_eq)="field">
        <div>
          <strong>{{ field.item.atk.eq_wins | percentage(field.item.atk.eq_total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.atk.eq_wins }} / {{ field.item.atk.eq_total }})</span>
        </div>
      </template>

      <template #cell(atk_dadv)="field">
        <div>
          <strong>{{ field.item.atk.dadv_wins | percentage(field.item.atk.dadv_total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.atk.dadv_wins }} / {{ field.item.atk.dadv_total }})</span>
        </div>
      </template>

      <template #cell(def)="field">
        <div>
          <strong>{{ field.item.def.total }}</strong>
        </div>
      </template>

      <template #cell(def_adv)="field">
        <div>
          <strong>{{ field.item.def.adv_wins | percentage(field.item.def.adv_total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.def.adv_wins }} / {{ field.item.def.adv_total }})</span>
        </div>
      </template>

      <template #cell(def_eq)="field">
        <div>
          <strong>{{ field.item.def.eq_wins | percentage(field.item.def.eq_total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.def.eq_wins }} / {{ field.item.def.eq_total }})</span>
        </div>
      </template>

      <template #cell(def_dadv)="field">
        <div>
          <strong>{{ field.item.def.dadv_wins | percentage(field.item.def.dadv_total) }}</strong>
          <span class="text-muted ml-1">({{ field.item.def.dadv_wins }} / {{ field.item.def.dadv_total }})</span>
        </div>
      </template>

      <template #row-details="row">
        <h3>{{ firstStateTitle[firstStateFilter] }}</h3>
        <div style="display: flex; flex-direction: row">
          <AppTable :items="row.item.atk[firstStateFilter + '_items']" :fields="xvyFields">
            <template #thead-top>
              <b-tr>
                <b-th colspan="2" class="atk">ATK</b-th>
              </b-tr>
            </template>

            <template #cell(wr)="field">
              <div>
                <strong>{{ field.item.wr.wins | percentage(field.item.wr.total) }}</strong>
                <span class="text-muted ml-1">({{ field.item.wr.wins }} / {{ field.item.wr.total }})</span>
              </div>
            </template>
          </AppTable>

          <AppTable :items="row.item.def[firstStateFilter + '_items']" :fields="xvyFields">
            <template #thead-top>
              <b-tr>
                <b-th colspan="2" class="def"> DEF</b-th>
              </b-tr>
            </template>

            <template #cell(wr)="field">
              <div>
                <strong>{{ field.item.wr.wins | percentage(field.item.wr.total) }}</strong>
                <span class="text-muted ml-1">({{ field.item.wr.wins }} / {{ field.item.wr.total }})</span>
              </div>
            </template>
          </AppTable>
        </div>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BIconCaretRightFill } from 'bootstrap-vue'

import AppTable from '@/components/generic/Table.vue'
import AgentComposition from '@/components/UI/AgentComposition.vue'

import percentage from '../../filters/percentage.js'
export default {
  name: 'OpeningDuelTable',
  components: {
    AgentComposition,
    AppTable,
    BIconCaretRightFill,
  },
  props: {
    stats: [],
  },
  data() {
    return {
      fkdFilter: 'all',
      fkdKeys: {
        all: '',
        true: 'true_',
        traded: 'traded_',
      },
      fkdFields: [
        { key: 'comp', label: 'Team Composition', thClass: 'text-left', tdClass: 'text-left' },
        { key: 'atk', label: 'ATK Rounds', thClass: 'atk' },
        { key: 'atk_fk', label: 'FK', thClass: 'atk' },
        { key: 'atk_fd', label: 'FD', thClass: 'atk' },
        { key: 'def', label: 'DEF Rounds', thClass: 'def' },
        { key: 'def_fk', label: 'FK', thClass: 'def' },
        { key: 'def_fd', label: 'FD', thClass: 'def' },
      ],
      firstStateFilter: 'adv',
      firstStateStable: false,
      firstStateFields: [
        { key: 'comp', label: 'Team Composition', thClass: 'text-left', tdClass: 'text-left' },
        { key: 'atk', label: 'ATK Rounds', thClass: 'atk' },
        { key: 'atk_adv', label: 'Adv.', thClass: 'atk' },
        { key: 'atk_eq', label: 'Equal', thClass: 'atk' },
        { key: 'atk_dadv', label: 'Disadv.', thClass: 'atk' },
        { key: 'def', label: 'DEF Rounds', thClass: 'def' },
        { key: 'def_adv', label: 'Adv.', thClass: 'def' },
        { key: 'def_eq', label: 'Equal', thClass: 'def' },
        { key: 'def_dadv', label: 'Disadv.', thClass: 'def' },
      ],
      firstStateTitle: {
        adv: 'Man Advantage',
        eq: 'Equal',
        dadv: 'Man Disadvantage',
      },
      xvyFields: [
        { key: 'xvy', label: 'XvY' },
        { key: 'wr', label: 'Win Rate' },
      ],
    }
  },
  filters: {
    percentage,
  },
  computed: {
    firstStateStats() {
      let stats = []
      this.stats.forEach(s => {
        stats.push({
          comp: s.comp,
          atk: { ...this.getTotalFirstStateStats(s.atk), total: s.atk.total },
          def: { ...this.getTotalFirstStateStats(s.def), total: s.def.total },
        })
      })
      return stats
    },
  },
  methods: {
    clickFKDFilter(value) {
      this.fkdFilter = value
    },
    clickFirstStateFilter(value) {
      this.firstStateFilter = value
    },
    clickFirstStateStable() {
      this.firstStateStable = !this.firstStateStable
    },
    getTotalFirstStateStats(data) {
      let stats = {
        adv_wins: 0,
        adv_total: 0,
        adv_items: {},
        eq_wins: 0,
        eq_total: 0,
        eq_items: {},
        dadv_wins: 0,
        dadv_total: 0,
        dadv_items: {},
      }

      const keys = ['adv', 'eq', 'dadv']

      for (let k of keys) {
        data[k].forEach(s => {
          if (this.firstStateStable && !s.stable) return
          if (!(s.xvy in stats[k + '_items'])) stats[k + '_items'][s.xvy] = { wins: 0, total: 0 }
          stats[k + '_wins'] += s.win
          stats[k + '_total'] += 1
          stats[k + '_items'][s.xvy].wins += s.win
          stats[k + '_items'][s.xvy].total += 1
        })

        stats[k + '_items'] = Object.entries(stats[k + '_items'])
          .map(x => ({ xvy: x[0], wr: x[1] }))
          .sort((a, b) => (b.xvy > a.xvy ? 1 : -1))
      }
      return stats
    },
  },
}
</script>

<style scoped lang="scss">
.buttonGroup {
  width: 640px;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  place-items: center;
  margin-bottom: 8px;
}

button {
  width: max-content;
  min-width: 60px;
  background-color: #282828;
  color: white;
  border: 1px solid black;
  border-radius: 0.5rem;
}

.stableButton {
  margin-left: auto;
  background-color: #1e40af;
}

.active {
  background-color: white;
  color: black;
  font-weight: 700;
}

.def {
  background-color: #099b83;
}

.atk {
  background-color: #ac493a;
}

.tooltipmain {
  display: inline-block;
  margin-left: 8px;
  text-align: center;
  justify-self: end;
  background-color: white;
  color: black;
  font-weight: 700;
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  cursor: pointer;
  position: relative;
}

.tooltipmain .tooltiptext {
  visibility: hidden;
  width: 500px;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  background-color: #e4e4e7;
  color: black;
  text-align: left;
  border-radius: 1rem;
  padding: 8px;
  position: absolute;
  z-index: 1;
  left: 650%;
  top: -36px;
  margin-left: -120px;
  font-size: 0.75rem;
  line-height: 1rem;
}

.tooltipmain:hover .tooltiptext {
  visibility: visible;
}

.toggle-details-icon {
  margin-right: 1em;
  transition: 0.2s;
}

.collapsed {
  transform: rotate(0);
}

.expanded {
  transform: rotate(90deg);
}

::v-deep {
  .cursor-pointer:not(.b-table-details) {
    cursor: pointer;
  }

  &.table-hover .table-dark:hover {
    background-color: #131313;
  }

  td,
  th {
    text-align: center;
  }
  .def {
    background-color: #099b83;
  }

  .atk {
    background-color: #ac493a;
  }
}
</style>
