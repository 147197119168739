<template>
  <div class="main-wrapper">
    <div class="stats">
      <WinRateTable :stats="allStats" />

      <OpeningDuelTable :stats="allStats" />

      <PostPlantTable :stats="allStats" />

      <UltTable :stats="allStats" />
    </div>

    <div class="matchSelector">
      <h2>Selected Matches</h2>
      <div class="matchWrapper">
        <template v-for="m in data">
          <div :key="m.match" class="card" style="user-select: none">
            <input type="checkbox" :id="m.match" :value="m" :name="mapName" v-model="selected_matches" />
            <label :for="m.match">
              <AgentComposition :agent_ids="getCompId(m.team_comp)" size="sm" />
              <span :class="{ win: m.win, loss: !m.win }">
                {{ m.round_wins }}
              </span>
              <span> -{{ m.round_losses }} </span>
            </label>
            {{ m.scrim_date }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import OpeningDuelTable from '@/components/ScrimStats/OpeningDuelTable.vue'
import PostPlantTable from '@/components/ScrimStats/PostPlantTable.vue'
import UltTable from '@/components/ScrimStats/UltTable.vue'
import WinRateTable from '@/components/ScrimStats/WinRateTable.vue'
import AgentComposition from '@/components/UI/AgentComposition.vue'

export default {
  name: 'SelectedMapStats',
  components: { WinRateTable, OpeningDuelTable, PostPlantTable, UltTable, AgentComposition },
  props: {
    mapName: String,
    data: [],
  },
  data() {
    return {
      selected_matches: [],
    }
  },
  mounted() {
    this.selected_matches = this.data
  },
  computed: {
    ...mapGetters({
      getAgentByName: 'static/getAgentByName',
    }),
    allStats() {
      if (this.selected_matches.length === 0) return []
      else if (this.selected_matches.length === 1) return this.statsByComp()
      else return [{ ...this.calculateStats(this.selected_matches), comp: 'ALL' }, ...this.statsByComp()]
    },
  },
  methods: {
    getAgentImg(agent) {
      return this.getAgentByName(agent).display_icon_small_url
    },
    getAgentId(agent) {
      return this.getAgentByName(agent).id
    },
    statsByComp() {
      let comps = {}
      const comp_list = this.selected_matches.map(m => ({ comp: m.team_comp, match: m }))
      for (let c of comp_list) {
        let comp_id = c.comp.join('')
        if (!(comp_id in comps)) comps[comp_id] = { comp: c.comp.map(a => this.getAgentId(a)), matches: [] }
        comps[comp_id].matches.push(c.match)
      }

      let stat_list = []
      Object.keys(comps).forEach(key => {
        stat_list.push({ ...this.calculateStats(comps[key].matches), comp: comps[key].comp })
      })
      return stat_list
    },
    getCompId(agents) {
      return agents.map(a => this.getAgentId(a))
    },
    createSideStats() {
      return {
        wins: 0,
        total: 0,
        pistol_wins: 0,
        pistol_total: 0,
        fk_wins: 0,
        fk_total: 0,
        true_fk_wins: 0,
        true_fk_total: 0,
        traded_fk_wins: 0,
        traded_fk_total: 0,
        fd_wins: 0,
        fd_total: 0,
        true_fd_wins: 0,
        true_fd_total: 0,
        traded_fd_wins: 0,
        traded_fd_total: 0,
        adv: [],
        dadv: [],
        eq: [],
        postplant_wins: 0,
        postplant_total: 0,
        postplant_xvy: [],
        ult_pre_wins: 0,
        ult_pre_total: 0,
        ult_pre_plants: 0,
        ult_pre_defuses: 0,
        ult_pre_items: [],
        ult_post_wins: 0,
        ult_post_total: 0,
        ult_post_items: [],
      }
    },
    calculateStats(matches) {
      let curr = {
        match: {
          wins: 0,
          total: matches.length,
        },
        atk: this.createSideStats(),
        def: this.createSideStats(),
      }

      const wr_keys = ['wins', 'total', 'pistol_wins', 'pistol_total']
      const fkd_keys = [
        'fk_wins',
        'fk_total',
        'true_fk_wins',
        'true_fk_total',
        'traded_fk_wins',
        'traded_fk_total',
        'fd_wins',
        'fd_total',
        'true_fd_wins',
        'true_fd_total',
        'traded_fd_wins',
        'traded_fd_total',
      ]
      const ult_keys = [
        'ult_pre_wins',
        'ult_pre_total',
        'ult_pre_plants',
        'ult_pre_defuses',
        'ult_post_wins',
        'ult_post_total',
      ]
      matches.forEach(match => {
        curr.match.wins += match.win
        // WR
        curr.atk = this.sumStats(curr.atk, match.atk, wr_keys)
        curr.def = this.sumStats(curr.def, match.def, wr_keys)
        // FKD
        curr.atk = this.sumStats(curr.atk, this.getFKDStats(match.atk.first_kill, match.atk.first_death), fkd_keys)
        curr.def = this.sumStats(curr.def, this.getFKDStats(match.def.first_kill, match.def.first_death), fkd_keys)
        // FIRST STATE - Filtered ARRAYS - Calculation will be done in OpeningDuelTable component
        for (let side of ['atk', 'def']) {
          match[side].first_state.forEach(s => {
            let type = 'eq'
            if (s.type !== 0) type = s.type === -1 ? 'dadv' : 'adv'
            curr[side][type].push({
              stable: s.state.stable,
              xvy: `${s.state.team}v${s.state.enemy}`,
              win: s.state.win,
            })
          })
        }
        //POSTPLANT
        let plant_atk = this.getPostPlantStats(match.atk.postplant)
        let plant_def = this.getPostPlantStats(match.def.postplant)
        curr.atk = this.sumStats(curr.atk, plant_atk, ['postplant_wins', 'postplant_total'])
        curr.def = this.sumStats(curr.def, plant_def, ['postplant_wins', 'postplant_total'])
        curr.atk.postplant_xvy.push(...plant_atk.postplant_xvy)
        curr.def.postplant_xvy.push(...plant_def.postplant_xvy)
        //ULTS
        let ult_atk = this.getUltUsageStats(match.atk.ults)
        let ult_def = this.getUltUsageStats(match.def.ults)
        curr.atk = this.sumStats(curr.atk, ult_atk, ult_keys)
        curr.def = this.sumStats(curr.def, ult_def, ult_keys)
        curr.atk.ult_pre_items.push(...ult_atk.ult_pre_items)
        curr.def.ult_pre_items.push(...ult_def.ult_pre_items)
        curr.atk.ult_post_items.push(...ult_atk.ult_post_items)
        curr.def.ult_post_items.push(...ult_def.ult_post_items)
        // END OF MATCH LOOP
      })

      curr.atk.postplant_xvy.sort((a, b) => b.wr.team - a.wr.team || a.wr.team - a.wr.enemy - (b.wr.team - b.wr.enemy))
      curr.def.postplant_xvy.sort((a, b) => b.wr.team - a.wr.team || a.wr.team - a.wr.enemy - (b.wr.team - b.wr.enemy))
      return curr
    },
    sumStats(curr, arr, keys) {
      keys.forEach(k => {
        curr[k] += arr[k]
      })
      return curr
    },
    getFKDStats(fk, fd) {
      let stats = {
        fk_wins: 0,
        fk_total: fk.length,
        true_fk_wins: 0,
        true_fk_total: 0,
        traded_fk_wins: 0,
        traded_fk_total: 0,
        fd_wins: 0,
        fd_total: fd.length,
        true_fd_wins: 0,
        true_fd_total: 0,
        traded_fd_wins: 0,
        traded_fd_total: 0,
      }

      fk.forEach(k => {
        stats.fk_wins += k.win
        let is_traded = k.traded ? 'traded_fk_' : 'true_fk_'
        stats[is_traded + 'wins'] += k.win
        stats[is_traded + 'total'] += 1
      })

      fd.forEach(k => {
        stats.fd_wins += k.win
        let is_traded = k.traded ? 'traded_fd_' : 'true_fd_'
        stats[is_traded + 'wins'] += k.win
        stats[is_traded + 'total'] += 1
      })
      return stats
    },
    getPostPlantStats(plants) {
      let stats = {
        postplant_wins: 0,
        postplant_total: plants.length,
        postplant_xvy: {},
      }

      plants.forEach(p => {
        stats.postplant_wins += p.win
        const xvy = `${p.team}v${p.enemy}`
        if (!(xvy in stats.postplant_xvy))
          stats.postplant_xvy[xvy] = { wins: 0, total: 0, team: p.team, enemy: p.enemy }
        stats.postplant_xvy[xvy].wins += p.win
        stats.postplant_xvy[xvy].total += 1
      })
      stats.postplant_xvy = Object.entries(stats.postplant_xvy).map(x => ({ xvy: x[0], wr: x[1] }))

      return stats
    },
    getUltUsageStats(ults) {
      let stats = {
        ult_pre_wins: 0,
        ult_pre_total: 0,
        ult_pre_plants: 0,
        ult_pre_defuses: 0,
        ult_pre_items: [],
        ult_post_wins: 0,
        ult_post_total: 0,
        ult_post_items: [],
      }

      ults.forEach(u => {
        for (let status of ['pre', 'post']) {
          if (u[status].length === 0) continue
          if (status === 'pre') {
            stats['ult_pre_plants'] += u.planted ? 1 : 0
            stats['ult_pre_defuses'] += u.defused ? 1 : 0
          }
          stats['ult_' + status + '_wins'] += u.win
          stats['ult_' + status + '_total'] += 1

          stats['ult_' + status + '_items'].push({
            win: u.win ? true : false,
            planted: u.planted,
            ults: u[status].map(a => this.getAgentId(a.agent)),
          })
        }
      })
      return stats
    },
  },
}
</script>

<style scoped lang="scss">
h2 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.main-wrapper {
  display: grid;
  grid-template-columns: 1fr 250px;
}

.stats {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.matchSelector {
  margin-top: 10px;
  width: 250px;
  border: 2px solid black;
  height: 640px;
  padding: 4px;
  border-radius: 0.5rem;
  overflow: auto;
}

.matchWrapper {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.card {
  color: black;
  border-radius: 0.5rem;
  display: inline-flex;
  place-items: center;
  font-weight: 600;

  label {
    display: inline-flex;
    place-items: center;
  }
}

.box {
  opacity: 0%;
  visibility: hidden;
}

.agentImg {
  width: 24px;
  height: 24px;
  border-radius: 9999px;
}

.win {
  color: green;
}

.loss {
  color: red;
}
</style>
