<template>
  <div>
    <AppTable :items="stats" :fields="fields">
      <template #thead-top>
        <b-tr>
          <b-th colspan="5" style="text-align: left"> POST-PLANT </b-th>
        </b-tr>
      </template>

      <template #cell(comp)="row">
        <div :title="row.item.title" v-b-tooltip.hover.noninteractive style="display: inline-flex; align-items: center">
          <BIconCaretRightFill
            :class="row.detailsShowing ? 'expanded' : 'collapsed'"
            class="toggle-details-icon"
            @click="row.toggleDetails"
          />
          <div v-if="row.item.comp === 'ALL'">ALL</div>
          <div v-else>
            <AgentComposition :agent_ids="row.item.comp" size="sm" />
          </div>
        </div>
      </template>

      <template #cell(atk)="field">
        <div>
          <strong>{{ field.item.atk.total }}</strong>
        </div>
      </template>

      <template #cell(atk_plants)="field">
        <div>
          <strong>{{ field.item.atk.postplant_wins | percentage(field.item.atk.postplant_total) }}</strong>
          <span class="text-muted ml-1"
            >({{ field.item.atk.postplant_wins }} / {{ field.item.atk.postplant_total }})</span
          >
        </div>
      </template>

      <template #cell(def)="field">
        <div>
          <strong>{{ field.item.def.total }}</strong>
        </div>
      </template>

      <template #cell(def_plants)="field">
        <div>
          <strong>{{ field.item.def.postplant_wins | percentage(field.item.def.postplant_total) }}</strong>
          <span class="text-muted ml-1"
            >({{ field.item.def.postplant_wins }} / {{ field.item.def.postplant_total }})</span
          >
        </div>
      </template>

      <template #row-details="row">
        <div style="display: flex; flex-direction: row">
          <AppTable :items="row.item.atk.postplant_xvy" :fields="xvyFields">
            <template #thead-top>
              <b-tr>
                <b-th colspan="2" class="atk">ATK</b-th>
              </b-tr>
            </template>

            <template #cell(wr)="field">
              <div>
                <strong>{{ field.item.wr.wins | percentage(field.item.wr.total) }}</strong>
                <span class="text-muted ml-1">({{ field.item.wr.wins }} / {{ field.item.wr.total }})</span>
              </div>
            </template>
          </AppTable>
          <AppTable :items="row.item.def.postplant_xvy" :fields="xvyFields">
            <template #thead-top>
              <b-tr>
                <b-th colspan="2" class="def">DEF</b-th>
              </b-tr>
            </template>

            <template #cell(wr)="field">
              <div>
                <strong>{{ field.item.wr.wins | percentage(field.item.wr.total) }}</strong>
                <span class="text-muted ml-1">({{ field.item.wr.wins }} / {{ field.item.wr.total }})</span>
              </div>
            </template>
          </AppTable>
        </div>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BIconCaretRightFill } from 'bootstrap-vue'

import AppTable from '@/components/generic/Table.vue'
import AgentComposition from '@/components/UI/AgentComposition.vue'

import percentage from '../../filters/percentage.js'

export default {
  name: 'PostPlantTable',
  components: {
    AgentComposition,
    AppTable,
    BIconCaretRightFill,
  },
  props: {
    stats: [],
  },
  data() {
    return {
      fields: [
        { key: 'comp', label: 'Team Composition', thClass: 'text-left', tdClass: 'text-left' },
        { key: 'atk', label: 'ATK Rounds', thClass: 'atk' },
        { key: 'atk_plants', label: 'Postplant Winrate', thClass: 'atk' },
        { key: 'def', label: 'DEF Rounds', thClass: 'def' },
        { key: 'def_plants', label: 'Postplant Winrate', thClass: 'def' },
      ],
      xvyFields: [
        { key: 'xvy', label: 'XvY' },
        { key: 'wr', label: 'Win Rate' },
      ],
    }
  },
  filters: {
    percentage,
  },
}
</script>

<style scoped lang="scss">
.buttonGroup {
  width: 640px;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  place-items: center;
  margin-bottom: 8px;
}

button {
  width: max-content;
  min-width: 60px;
  background-color: #282828;
  color: white;
  border: 1px solid black;
  border-radius: 0.5rem;
}

.def {
  background-color: #099b83;
}

.atk {
  background-color: #ac493a;
}

.active {
  font-weight: 700;
  border: 1px solid white;
}

.toggle-details-icon {
  margin-right: 1em;
  transition: 0.2s;
}

.collapsed {
  transform: rotate(0);
}

.expanded {
  transform: rotate(90deg);
}

::v-deep {
  .cursor-pointer:not(.b-table-details) {
    cursor: pointer;
  }

  &.table-hover .table-dark:hover {
    background-color: #131313;
  }

  td,
  th {
    text-align: center;
  }

  .atk {
    background-color: #ac493a;
  }

  .def {
    background-color: #099b83;
  }
}
</style>
